




import {Component, Mixins, Vue} from 'vue-property-decorator'
import ScanComponent from '@/modules/scan/views/ScanComponent.vue'
import { ScanTypes } from '@/modules/scan/models/Scan'
import ConsignmentService from "@/modules/shipments/services/ConsignmentService";

@Component({
  components: {
    ScanComponent
  }
})
export default class Scan extends Mixins(ConsignmentService) {
  decode(qrcode: string) {
    const params = JSON.parse(qrcode)

    if (params.hasOwnProperty('type') && params.type == ScanTypes.MESSAGE) {
      this.$store.commit('scan/setMessage', params)
      this.$router.push({ name: 'scan-confirmation' })
    } else {
      this.$router.push({ name: 'scan-info', params: {consignment: qrcode } })
    }
  }
}
